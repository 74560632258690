import React, { Component } from 'react';
import promiseHandler from '../tools/promiseHandler'
import localization from '../tools/localized_strings';


class AuthView extends Component {
    render() {
        return (
            <div> 
                {localization[['gen.authenticating']]}
            </div>
        );
    }

    componentDidMount(){
        let token = new URLSearchParams(window.location.search).get('token');
        let tokenType = new URLSearchParams(window.location.search).get('stytch_token_type');
        let authRequest = {
            'method': 'GET',
            'url': '/v1/auth/token-auth',
            'params': {
                'token': token,
                'stytch_token_type': tokenType
            }
        }

        
        promiseHandler.tokenVerificationRequest(authRequest);
    }
}
export default AuthView;