import PubSub from '../tools/pub_sub';
import mixpanel from 'mixpanel-browser';
import helperFunctions from '../tools/helperFunctions';
import pub_sub from '../tools/pub_sub';
import localization from './localized_strings';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

const axios = require('axios')

const userData = JSON.parse(window.localStorage.getItem('userData'));
let baseURL = process.env.REACT_APP_BASE_URL;


function promiseRequest(request){
  return new Promise((resolve) => {
    PubSub.publish.is_loading(true)
    try {      
      let authType = helperFunctions.getTokenCookie() !== ""?("Bearer " + JSON.parse(helperFunctions.getTokenCookie()).token): "None"
      axios({
        headers:{
          'Content-Type': 'application/json',
          'Authorization': authType,
        },
        method: request.method,
        url: baseURL + request.url,
        data: request.data,
        params: request.params
      })
      .then(response => {
        
        if (response.data.user && userData){
          if(response.data.user.updated_ts > userData.updated_ts){
            window.localStorage.setItem('userData', JSON.stringify(response.data.user));
            //reload if subscription status has changed
            /*
            if()
            if(userData.subscription.status !== response.data.user.subscription.status){
              console.log("reload");
              window.location.reload()
            }
            */
          }
        }
        resolve(response)
      })
      .catch(error => {
        handleError(error)
        resolve(error)  
      })
      .then(()=>{
          PubSub.publish.is_loading(false)
      })
    }
    catch (error) {
      handleError(error)
      resolve(error)
    }

  })
}

function verifyUser(request){
  PubSub.publish.is_loading(true)
  return new Promise((resolve) => {
    axios({
      headers:{
        'Content-Type': 'application/json',
      },
      method: 'POST',
      url: baseURL + request.url,
      data: request.data
    })
    .then(response => {
      if (response.status === 201) {
        resolve()
      }
    })
    .catch(error => {
        handleError(error);
        resolve(error.response)
    })
    .then(()=>{
      PubSub.publish.is_loading(false)
    })
  })
}

function tokenVerificationRequest(request){
  PubSub.publish.is_loading(true)
  return new Promise((resolve) => {
    axios({
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + request.auth
      },
      method: 'POST',
      url: baseURL + request.url,
      params: request.params
    })
    .then(response => {
      if (response.status === 200) {
        helperFunctions.setTokenCookie(response.data.session_token)
        const responseData = response.data.user;
        window.localStorage.setItem('userData', JSON.stringify(responseData));

        if(!process.env.REACT_APP_TEST_ENVIRONMENT){
          mixpanel.identify(responseData.email)
          mixpanel.people.set({
            'first_name': responseData.first_name,
            'last_name': responseData.last_name,
            '$email': responseData.email,
            'stripe_id': responseData.stripe_id,
            'stytch_id': responseData.stytch_id
          })
        }

        let subscribed = false;
        if(responseData.subscription){
          if(responseData.subscription.status === 'active' || responseData.subscription.status === 'trialing'){
            subscribed =true
          }
        }

        if(subscribed === false && responseData.school_subscription){
          if(responseData.school_subscription.subscription_status === 'active'){
            subscribed = true
          }
        }
        
        try {
          if(subscribed){
            if(responseData.first_name ==="" || responseData.last_name ===""){
              window.location.replace('/register')
            }
            else{
              const _hsq = window._hsq = window._hsq || [];
              _hsq.push(["identify",{
                  email: responseData.email,
                  firstname: responseData.first_name,
                  lastname: responseData.last_name
              }]);
              
               window.location.replace("/?login=true");
            }
          }
          else{
            window.location.replace("/subscribe")

          }
        } catch (error) {

          pub_sub.publish.new_toast_message({
              type: 'error',
              header: localization['toast.gen_error_header'],
              message:  localization['toast.gen_error_body']
          })
        }
        
        resolve()
      }
    })
    .catch(error => {
        handleError(error);
        resolve(error.response)
    })
    .then(()=>{
      PubSub.publish.is_loading(false)
    })
  })
}

function handleError(error){
  if (error.message === "Network Error"){
     mixpanel.track("error",{
        status: "Unknown",
        response: "Error has no response"
      }
    )

    pub_sub.publish.new_toast_message({
      type: 'error',
      header: localization['toast.gen_error_header'],
      message:  localization['toast.gen_error_body'],
    })    
        window.location.replace('/404')
                    
    return
  }
  if (error.response) {
    if (error.response.status === 401) {
      helperFunctions.removeCredentials(401);
    }
    else if(error.response.status === 402){
      window.location.replace("/subscribe");
    }
    else if(error.response.status >= 500){
      mixpanel.track("error",{
          status: 500,
          response: error.response,
          location: window.location.pathname
        }
      )
      pub_sub.publish.new_toast_message({
          type: 'error',
          header: localization['toast.gen_error_header'],
          message:  localization['toast.gen_error_body']
      })
    }
  }
  else if(helperFunctions.getTokenCookie() === ""){
    mixpanel.track("error",{
        status: 404,
        response: "missing token cookie",
        location: window.location.pathname
      }
    )
    helperFunctions.removeCredentials(404)
  }
  else{
    mixpanel.track("error",{
        status: "Unknown",
        response: "Error has no response"
      }
    )
    pub_sub.publish.new_toast_message({
      type: 'error',
      header: localization['toast.gen_error_header'],
      message:  localization['toast.gen_error_body'],
    })
  }
}

let promiseHandler = {
  promiseRequest, tokenVerificationRequest,  verifyUser
}

export default promiseHandler;
