import React, { Component } from 'react';
import { StytchProvider, StytchLogin } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import pub_sub from '../tools/pub_sub';
import '../css/onboarding/login.scss';
import '../css/onboarding/onboarding.scss';
import localization from '../tools/localized_strings';

const stytch = new StytchUIClient(process.env.REACT_APP_STYTCH_TOKEN);
const stytchProps = {
  config: {
    products: ["oauth", "emailMagicLinks"],
    emailMagicLinksOptions: {
      loginRedirectURL: process.env.REACT_APP_STYTCH_LOGIN_LINK,
      loginExpirationMinutes: 30,
      signupRedirectURL: process.env.REACT_APP_STYTCH_SIGN_UP_LINK,
      signupExpirationMinutes: 30,
      createUserAsPending: true,
    },
    oauthOptions: {
      providers: [{ type: "google" }, { type: "microsoft" }],
      loginRedirectURL: process.env.REACT_APP_STYTCH_LOGIN_LINK,
      signupRedirectURL: process.env.REACT_APP_STYTCH_SIGN_UP_LINK,
    },
  },
  styles: {
    container: {
      width: "100%",
    },
    buttons: {
      primary: {
        backgroundColor: "#4B88FF",
        color: "#323030",
      },
    },
    fontFamily: '"Mier A"',
    colors: {
      primary: "#4B88FF",
      text: "#323030",
      secondary: "#888888",
      gray: "#9D9D9D",
    },
    hideHeaderText: true,
  },
  callbacks: {
    onEvent: (message) => console.log(message),
    onSuccess: (message) => console.log(message),
    onError: (message) => console.log(message),
  },
};

class LoginView extends Component {
    render() {
        return (
              <div className="onboarding-view main">
                <div className="onboarding-container">
                    <div className='onboarding-header'>
                        <img onClick={()=> window.location.href='/'} alt="folio logo" className='logo' src="https://cc-cdn.pss.co/assets/brand/folio_logo.svg"/>
                         {(window.location.pathname === "/login"?
                                <span className='c1'>{localization['auth.create_account']}?&nbsp;<a href='/create-account'>{localization['gen.create_account']}</a></span>:
                                <span className='c1'>{localization['auth.log_in']}&nbsp;<a href='/login'>{localization['gen.log_in']}</a></span>
                            )}
                    </div>
                    <div className="onboarding-info">
                    <StytchProvider stytch={stytch}>
                        <div id="login_container" className="login-container">
                            <h2><b>{(window.location.pathname === "/login"? localization['gen.log_in']:localization['gen.create_account'])}</b></h2>
                                <StytchLogin 
                                    config={stytchProps.config}
                                    styles={stytchProps.styles}
                                callbacks={stytchProps.callbacks}
                             />
                        </div>
                    </StytchProvider>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(){        
        let urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('session_expired')){
            pub_sub.publish.new_toast_message({
                type: 'error',
                header: localization['toast.expired_session_header'],
                message: localization['toast.expired_session_body'],
            })
        }
    }
}
export default LoginView;